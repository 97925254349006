import { useCallback, useEffect, useRef, useState } from 'react';
import { SnackbarKey, SnackbarOrigin, useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import {
  FILE_UPLOAD_AUDIO_SIZE_LIMIT,
  BYTES_PER_MB,
  FILE_UPLOAD_AUDIO_SIZE_LIMIT_MB,
} from '../../../../constants';
import { CreateNarrationParams } from '../../../lesson/lib/steps/use-steps-mutations/use-steps-mutations.type';
import { useCreateFileMutation } from '../../../../api/endpoints/file';
import { useCreateStepNarrationMutation } from '../../../../api/endpoints/step';
import { useCreateElementMutation } from '../../../../api/endpoints/element';
import { Line } from '../../../pointer';
import { CreateElementParams } from '../../../lesson/lib/elements/use-elements-mutations/use-elements-mutations.type';
import { positionByElementType } from '../../../lesson/lib/elements/use-elements-mutations/element-payload-constructors/element-payload-constructors.constant';
import {
  AnimationHighlightChangePropsWithDelayAndDuration,
  useCreateAnimationMutation,
} from '../../../../api/endpoints/animation';
import { useAppTranslation } from '../../../../locales/i18n';
import { SnackbarActions } from '../../ui/snackbar-actions';
import { setHaveUnsavedChanges } from '../../../../redux/slices/views.slice';
import { UseUploaderInternalReturn } from './use-uploader-internal.type';

const SNACKBAR_POSITION: SnackbarOrigin = {
  vertical: 'top',
  horizontal: 'right',
};

export const useUploaderInternal = (): UseUploaderInternalReturn => {
  const { t } = useAppTranslation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const [createFile] = useCreateFileMutation();
  const [createNarrationInternal] = useCreateStepNarrationMutation();
  const [createElementInternal] = useCreateElementMutation();
  const [createAnimationInternal] = useCreateAnimationMutation();

  const [uploadingNarrationsStepIds, setUploadingNarrationsStepIds] = useState<
    { stepId: string }[]
  >([]);

  const uploadingSnackbarKeyRef = useRef<SnackbarKey>();
  const retryFilesRef = useRef<{ [key: string]: File }>({});

  const downloadNarration = useCallback(
    (lessonId: string, stepId: string, slideId?: string) => {
      const file = retryFilesRef.current[stepId];
      if (!file) return;

      const url = URL.createObjectURL(file);
      const a = document.createElement('a');
      a.href = url;
      a.download = `lesson-${lessonId}-slide-${
        slideId || 'unknown'
      }-step-${stepId}`;
      a.click();
      URL.revokeObjectURL(url);
    },
    [],
  );

  const uploadPointer = useCallback(
    async (
      lessonId: string,
      slideId: string,
      stepId: string,
      lines: Line[],
      isFirstStep?: boolean,
    ) => {
      const params = { lessonId };
      const payload: CreateElementParams['payload'] = {
        type: 'pointer',
        slideId,
        value: {
          content: '',
          lines,
        },
        position: positionByElementType.pointer,
      };
      const element = await createElementInternal({
        params,
        payload,
      }).unwrap();
      if (!isFirstStep) {
        await createAnimationInternal({
          params: { lessonId },
          payload: {
            stepId,
            slideElementId: element.id,
            changeType: 'visibility',
            animationType: 'appear',
            changeProps: { visibility: true },
            animationProps: {},
            delay: 0,
            duration: 0,
          },
        });
      }
    },
    [createAnimationInternal, createElementInternal],
  );

  const uploadHighlights = useCallback(
    async (
      lessonId: string,
      stepId: string,
      elementId: string,
      highlights: AnimationHighlightChangePropsWithDelayAndDuration[],
    ) =>
      Promise.all(
        highlights.map(async (highlight) =>
          createAnimationInternal({
            params: { lessonId },
            payload: {
              stepId,
              slideElementId: elementId,
              changeType: 'highlight',
              animationType: 'appear',
              changeProps: { visibility: true },
              animationProps: {
                location: highlight.location,
                color: highlight.color,
              },
              delay: highlight.delay,
              duration: highlight.duration,
            },
          }),
        ),
      ),
    [createAnimationInternal],
  );

  const uploadNarration = useCallback(
    async (
      args: CreateNarrationParams,
      options: { isFirstStep?: boolean },
      attachments: {
        pointer?: { lines: Line[]; slideId: string };
        highlights?: {
          data: AnimationHighlightChangePropsWithDelayAndDuration[];
          elementId?: string;
        };
      },
    ) => {
      const { stepId, lessonId } = args.params;
      const { file } = args.payload;

      if (file.size > FILE_UPLOAD_AUDIO_SIZE_LIMIT) {
        enqueueSnackbar(
          t('step:list.item.feedback.audioFileTooBig', {
            audioSize: file.size / BYTES_PER_MB,
            limit: FILE_UPLOAD_AUDIO_SIZE_LIMIT_MB,
          }),
          {
            variant: 'error',
            anchorOrigin: SNACKBAR_POSITION,
          },
        );
        return;
      }

      setUploadingNarrationsStepIds((prev) => [...prev, { stepId }]);

      try {
        const formData = new FormData();
        formData.append('file', file);

        const createdFile = await createFile({ payload: formData }).unwrap();
        const assetId = createdFile.id;

        await createNarrationInternal({
          params: { stepId, lessonId },
          payload: { assetId },
        }).unwrap();
        setUploadingNarrationsStepIds((prev) => {
          const next = prev.filter((id) => id.stepId !== stepId);
          if (next.length === 0) {
            enqueueSnackbar(t('step:list.item.feedback.audioUploadSuccess'), {
              variant: 'success',
              anchorOrigin: SNACKBAR_POSITION,
            });
          }
          return next;
        });
        const { pointer, highlights } = attachments;
        if (pointer) {
          await uploadPointer(
            lessonId,
            pointer.slideId,
            stepId,
            pointer.lines,
            options.isFirstStep,
          );
        }

        if (highlights?.data.length && highlights?.elementId) {
          await uploadHighlights(
            lessonId,
            stepId,
            highlights.elementId,
            highlights.data,
          );
        }

        delete retryFilesRef.current[stepId];
      } catch (error) {
        const { pointer } = attachments;
        retryFilesRef.current[stepId] = file;
        setUploadingNarrationsStepIds((prev) =>
          prev.filter((id) => id.stepId !== stepId),
        );
        enqueueSnackbar(t('step:list.item.feedback.audioUploadError'), {
          variant: 'error',
          anchorOrigin: SNACKBAR_POSITION,
          persist: true,
          action: (key) =>
            SnackbarActions({
              actions: [
                {
                  onClick: () =>
                    uploadNarration(
                      {
                        params: { lessonId, stepId },
                        payload: { file },
                      },
                      options,
                      attachments,
                    ),
                  label: t('step:list.item.action.retry'),
                },
                {
                  onClick: () =>
                    downloadNarration(lessonId, stepId, pointer?.slideId),
                  label: t('step:list.item.action.download'),
                },
              ],
              snackbarKey: key,
            }),
        });
      }
    },
    [
      enqueueSnackbar,
      t,
      createFile,
      createNarrationInternal,
      uploadPointer,
      uploadHighlights,
      downloadNarration,
    ],
  );

  const isUploading = useCallback(
    (stepId?: string) =>
      uploadingNarrationsStepIds.some((n) => n.stepId === stepId),
    [uploadingNarrationsStepIds],
  );

  useEffect(() => {
    if (uploadingNarrationsStepIds.length > 0) {
      dispatch(setHaveUnsavedChanges(true));
      const key = enqueueSnackbar(
        t('step:list.item.feedback.audioUploadInfo', {
          count: uploadingNarrationsStepIds.length,
        }),
        {
          variant: 'info',
          anchorOrigin: SNACKBAR_POSITION,
          persist: true,
        },
      );
      if (uploadingSnackbarKeyRef.current) {
        closeSnackbar(uploadingSnackbarKeyRef.current);
      }
      uploadingSnackbarKeyRef.current = key;
    } else if (uploadingSnackbarKeyRef.current) {
      dispatch(setHaveUnsavedChanges(false));
      closeSnackbar(uploadingSnackbarKeyRef.current);
      uploadingSnackbarKeyRef.current = undefined;
    }
  }, [
    closeSnackbar,
    enqueueSnackbar,
    uploadingNarrationsStepIds.length,
    t,
    dispatch,
  ]);

  return {
    narration: {
      uploadingNarrations: uploadingNarrationsStepIds,
      upload: uploadNarration,
      helpers: {
        isUploading,
      },
    },
  };
};
