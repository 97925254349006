import Bugsnag from '@bugsnag/browser';
import { lessonStorageService } from '../../../storage';
import { isUserLoggedIn } from '../../../providers/user';
import { enqueueSnackbar } from '../../../utils/snackbar-helper';
import { elementStorageService } from '../../../storage/element';
import { isInsideIframe } from '../../../utils/is-inside-iframe';
import { transformResponseReadLesson } from './api-lesson.transformer';
import { Lesson } from './types';

export const transformStorageResponseReadLesson = async (
  lesson: ReturnType<typeof transformResponseReadLesson>,
): Promise<Lesson> => {
  if (isUserLoggedIn() && !isInsideIframe()) {
    return lesson;
  }

  try {
    const offlineLesson = await lessonStorageService.readOne(lesson.id);

    if (!offlineLesson) {
      return lesson;
    }

    const transformedSlides = await Promise.all(
      lesson.slides.map(async (slide) => {
        const codeSlideElementIds = slide.elements
          .filter(
            (element) =>
              element.type === 'code' || element.type === 'frontendCode',
          )
          .map((element) => element.id);

        const slideAssignmentsResults = await Promise.all(
          codeSlideElementIds.map(async (id) => {
            const element = await elementStorageService.readOne(id);
            return Boolean(element?.isAssignmentPassed);
          }),
        );

        const quizElementIds = slide.elements
          .filter((element) => element.type === 'quiz')
          .map((element) => element.id);

        const quizAnswered = await Promise.all(
          quizElementIds.map(async (id) => {
            const element = await elementStorageService.readOne(id);
            return Boolean(element?.isAssignmentPassed);
          }),
        );

        return {
          ...slide,
          assignmentPassed:
            slideAssignmentsResults.every(Boolean) &&
            quizAnswered.every(Boolean),
        };
      }),
    );

    const areAllAssignmentsPassed = transformedSlides.every(
      (slide) => slide.assignmentPassed,
    );

    return {
      ...lesson,
      currentSlide: offlineLesson.selectedSlideId,
      slides: transformedSlides,
      areAllAssignmentsPassed,
    };
  } catch (error) {
    if (error instanceof Error) {
      Bugsnag.notify(error);
    }

    enqueueSnackbar(
      'An error occurred while reading lesson as a guest user. Please try again or reach out to support.',
      { variant: 'error' },
    );

    return lesson;
  }
};
