import zod from './zod.json';
import common from './common.json';
import auth from './auth.json';
import layout from './layout.json';
import profile from './profile.json';
import lesson from './lesson.json';
import element from './element.json';
import course from './course.json';
import sharedPageLayout from './shared-page-layout.json';
import slide from './slide.json';
import step from './step.json';

export const localeEN = {
  zod,
  common,
  auth,
  layout,
  profile,
  lesson,
  element,
  course,
  slide,
  step,
  sharedPageLayout,
};
