import i18n, { KeyPrefix } from 'i18next';
import {
  UseTranslationOptions,
  initReactI18next,
  useTranslation,
} from 'react-i18next';
import { localeEN } from './en';

export const resources = {
  en: localeEN,
};

void i18n.use(initReactI18next).init({
  lng: 'en',
  fallbackLng: 'en',
  resources,
  interpolation: {
    escapeValue: false,
  },
  saveMissing: true,
  missingKeyHandler: (_lng, ns, key) => {
    const errorMsg = `"${key}" is missing from the "${ns}" namespace!`;
    throw new Error(errorMsg);
  },
});

i18n.services.formatter?.add('lowercase', (value: string) =>
  value.toLowerCase(),
);

i18n.services.formatter?.add(
  'capitalize',
  (value: string) => `${value.charAt(0).toUpperCase()}${value.slice(1)}`,
);

export const i18next = i18n;

export const useAppTranslation = (
  options: UseTranslationOptions<
    KeyPrefix<
      [
        'common',
        'layout',
        'auth',
        'course',
        'profile',
        'lesson',
        'slide',
        'step',
        'element',
        'sharedPageLayout',
      ]
    >
  > = {},
) =>
  useTranslation(
    [
      'common',
      'layout',
      'auth',
      'course',
      'profile',
      'lesson',
      'slide',
      'step',
      'element',
      'sharedPageLayout',
    ],
    options,
  );

export type TAppFunction = ReturnType<typeof useAppTranslation>['t'];
