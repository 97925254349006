import { createApi } from '@reduxjs/toolkit/query/react';
import axios, { AxiosError } from 'axios';
import Bugsnag from '@bugsnag/browser';
import { NonUndefined } from '@reduxjs/toolkit/dist/query/tsHelpers';
import { storageApi } from '../storage/storage-api';
import { isUserLoggedIn } from '../providers/user/is-user-logged-in';
import { enqueueSnackbar } from '../utils/snackbar-helper';
import { getResponseErrorMessage } from '../utils/get-response-error-message';
import { isInsideIframe } from '../utils/is-inside-iframe';
import { BaseQueryFuncOptions, Response } from './api.type';
import { BASE_URL, tagTypes } from './api-tags';

export const api = createApi({
  tagTypes,
  baseQuery: async ({
    url: endpointUrl,
    gateway = '1',
    path,
    serverErrorsToIgnore = [],
    method,
    params,
    payload,
    headers,
  }: BaseQueryFuncOptions) => {
    const isLoggedIn = isUserLoggedIn();
    const isShowcaseMode = isInsideIframe();
    const isRequestMutation = method !== 'get';
    const guestUserMutationAllowedURLs = [
      '/auth/register',
      '/auth/login',
      '/auth/resendVerificationEmail',
      '/auth/passwordReset',
      '/auth/passwordUpdate',
    ];
    const shouldSaveProgressLocally =
      !guestUserMutationAllowedURLs.some((url) =>
        endpointUrl.startsWith(url),
      ) &&
      (!isLoggedIn || isShowcaseMode) &&
      isRequestMutation;

    if (shouldSaveProgressLocally) {
      if (!path) {
        const error = new Error('Guest user mutation request without path');
        Bugsnag.notify(error);
        throw error;
      }

      try {
        const result = await storageApi.request(path, {
          params,
          payload,
        });

        return { data: result };
      } catch (error) {
        return { error };
      }
    }

    if (!BASE_URL) {
      throw new Error('BASE_URL_V1 or BASE_URL_V2 is not defined');
    }

    const isBackendLocal = BASE_URL.includes('localhost');
    const areBackendPortsMissing =
      !process.env.REACT_APP_SERVER_URL_V1_PORT ||
      !process.env.REACT_APP_SERVER_URL_V2_PORT;
    if (isBackendLocal && areBackendPortsMissing) {
      throw new Error(
        'REACT_APP_SERVER_URL_V1_PORT and/or REACT_APP_SERVER_URL_V2_PORT is not defined',
      );
    }

    const baseUrlByGateway: Record<
      NonUndefined<BaseQueryFuncOptions['gateway']>,
      string
    > = {
      1: isBackendLocal
        ? `${BASE_URL}:${process.env.REACT_APP_SERVER_URL_V1_PORT as string}`
        : // TODO: append `v2` to the base URL when this issue is resolved: https://auditorium-ai.atlassian.net/browse/AUD-1453
          `${BASE_URL}`,
      2: isBackendLocal
        ? `${BASE_URL}:${process.env.REACT_APP_SERVER_URL_V2_PORT as string}`
        : `${BASE_URL}/v2`,
    };

    const axiosClient = axios.create({
      withCredentials: true,
      baseURL: baseUrlByGateway[gateway],
      headers: {
        ...(process.env.REACT_APP_ENV !== 'production' && {
          'CF-Access-Client-Id': process.env.REACT_APP_STAGING_CF_TOKEN,
          'CF-Access-Client-Secret': process.env.REACT_APP_STAGING_CF_SECRET,
        }),
      },
    });

    axiosClient.interceptors.response.use(
      (response) => response,
      (error) => {
        const shouldDisplayError =
          error instanceof AxiosError &&
          error.response &&
          !serverErrorsToIgnore.includes(error.response?.status);

        if (shouldDisplayError) {
          enqueueSnackbar(getResponseErrorMessage(error), { variant: 'error' });
        }

        throw error;
      },
    );

    axiosClient.interceptors.request.use(
      (config) => config,
      (error) => {
        enqueueSnackbar(getResponseErrorMessage(error), { variant: 'error' });
        throw error;
      },
    );

    try {
      const { data: axiosData } = await axiosClient<Partial<Response>>(
        endpointUrl,
        {
          method,
          data: payload,
          headers,
        },
      );

      return { data: axiosData?.data || axiosData };
    } catch (error) {
      if (axios.isAxiosError(error)) {
        // raw axios error is not serializable, thus we need to extract the relevant (serializable) data to be stored in the redux store
        return {
          error: {
            status: error.response?.status,
            data: error.response?.data as unknown,
            message: error.message,
          },
        };
      }
      return { error };
    }
  },
  endpoints: () => ({}),
});
