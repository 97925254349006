import { api, API_TAGS } from '../../../../../api';
import {
  transformLoginUserPayload,
  transformRegisterUserPayload,
  transformResendEmailVerificationPayload,
  transformResetUserPasswordPayload,
} from './api-user.transformer';
import {
  DeleteUserQueryArgs,
  DeleteUserResponse,
  LoginUserQueryArgs,
  LoginUserResponse,
  LogoutUserQueryArgs,
  LogoutUserResponse,
  PersistGuestDataQueryArgs,
  PersistGuestDataResponse,
  ReadUserResponse,
  RegisterUserQueryArgs,
  RegisterUserResponse,
  ResendEmailVerificationQueryArgs,
  ResendEmailVerificationResponse,
  ResetUserPasswordQueryArgs,
  ResetUserPasswordResponse,
  UpdateUserPasswordQueryArgs,
  UpdateUserPasswordResponse,
  UpdateUserQueryArgs,
  UpdateUserResponse,
} from './api-user.type';

export const {
  useReadUserQuery,
  useLazyReadUserQuery,
  useUpdateUserMutation,
  useRegisterUserMutation,
  useLoginUserMutation,
  useLogoutUserMutation,
  useResendEmailVerificationMutation,
  useResetUserPasswordMutation,
  useUpdateUserPasswordMutation,
  usePersistGuestDataMutation,
  useDeleteUserMutation,
} = api.injectEndpoints({
  endpoints: (builder) => ({
    readUser: builder.query<ReadUserResponse, void>({
      query: () => ({
        url: '/users/me',
        method: 'get',
        gateway: '2',
      }),
      providesTags: (result) => (result ? [API_TAGS.user(result.id)] : []),
    }),

    updateUser: builder.mutation<UpdateUserResponse, UpdateUserQueryArgs>({
      query: ({ params, payload }) => ({
        url: `/users/${params.id}`,
        method: 'patch',
        payload,
      }),
      invalidatesTags: (_result, error, { params }) =>
        error ? [] : [API_TAGS.user(params.id)],
    }),

    registerUser: builder.mutation<RegisterUserResponse, RegisterUserQueryArgs>(
      {
        query: ({ payload }) => ({
          url: '/auth/register',
          method: 'post',
          payload: transformRegisterUserPayload(payload),
        }),
      },
    ),

    loginUser: builder.mutation<LoginUserResponse, LoginUserQueryArgs>({
      query: ({ payload }) => ({
        url: '/auth/login',
        serverErrorsToIgnore: [401],
        method: 'post',
        payload: transformLoginUserPayload(payload),
      }),
    }),

    logoutUser: builder.mutation<LogoutUserResponse, LogoutUserQueryArgs>({
      query: () => ({
        url: '/auth/logout',
        method: 'get',
      }),
      invalidatesTags: (_result, error, { params }) =>
        error ? [] : [API_TAGS.user(params.id)],
    }),

    resendEmailVerification: builder.mutation<
      ResendEmailVerificationResponse,
      ResendEmailVerificationQueryArgs
    >({
      query: ({ payload }) => ({
        url: '/auth/resendVerificationEmail',
        method: 'post',
        payload: transformResendEmailVerificationPayload(payload),
      }),
    }),

    updateUserPassword: builder.mutation<
      UpdateUserPasswordResponse,
      UpdateUserPasswordQueryArgs
    >({
      query: ({ params: { token }, payload }) => ({
        url: `/auth/passwordUpdate/${token}`,
        method: 'post',
        payload,
      }),
    }),

    resetUserPassword: builder.mutation<
      ResetUserPasswordResponse,
      ResetUserPasswordQueryArgs
    >({
      query: ({ payload }) => ({
        url: '/auth/passwordReset',
        method: 'post',
        payload: transformResetUserPasswordPayload(payload),
      }),
    }),

    persistGuestData: builder.mutation<
      PersistGuestDataResponse,
      PersistGuestDataQueryArgs
    >({
      query: ({ payload }) => ({
        url: '/auth/persist-guest-data',
        method: 'post',
        payload,
        invalidateTags: [API_TAGS.courses],
      }),
    }),

    deleteUser: builder.mutation<DeleteUserResponse, DeleteUserQueryArgs>({
      query: () => ({
        url: '/auth/deleteAccount',
        method: 'delete',
      }),
      invalidatesTags: (_result, error, { params }) =>
        error ? [] : [API_TAGS.user(params.id)],
    }),
  }),
});
