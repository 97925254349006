import Bugsnag from '@bugsnag/browser';
import {
  lessonStorageService,
  STORAGE_USE_ERROR_MESSAGE,
} from '../../../storage';
import { ElementInDB, elementStorageService } from '../../../storage/element';
import { isUserLoggedIn } from '../../../providers/user';
import { enqueueSnackbar } from '../../../utils/snackbar-helper';
import { isInsideIframe } from '../../../utils/is-inside-iframe';
import { transformStorageResponseReadLesson } from './api-lesson.storage-transformer';
import { Lesson } from './types';

export const updateStorageReadLesson = async (
  lesson: Awaited<ReturnType<typeof transformStorageResponseReadLesson>>,
): Promise<Lesson> => {
  if (isUserLoggedIn() && !isInsideIframe()) {
    return lesson;
  }

  try {
    await lessonStorageService.create(lesson);

    await Promise.all(
      lesson.slides.flatMap((slide) =>
        slide.elements.map((element) => {
          const offlineElementTypes: ElementInDB['type'][] = [
            'code',
            'frontendCode',
            'quiz',
          ];
          if (
            offlineElementTypes.includes(element.type as ElementInDB['type'])
          ) {
            const { id, type, value } = element;
            return elementStorageService.create({
              id,
              lessonId: lesson.id,
              type,
              value,
              isAssignmentPassed: slide.assignmentPassed,
            } as ElementInDB);
          }
          return null;
        }),
      ),
    );
  } catch (error) {
    if (error instanceof Error) {
      Bugsnag.notify(error);
    }
    enqueueSnackbar(STORAGE_USE_ERROR_MESSAGE, { variant: 'error' });
  }

  return lesson;
};
