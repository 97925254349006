import _ from 'lodash';
import { produce } from 'immer';
import { parseISO } from 'date-fns';
import { Animation } from '../animation/api-animation.type';
import { Element } from '../element';
import { AnimationComputedInStep } from './types';
import { Lesson, ReadLessonResponse } from './types/api-lesson.type';

const getComputedAnimation = (
  elements: Element[],
  animation: Animation,
  isMissingAnimation: boolean,
  computedAnimations: AnimationComputedInStep[],
): AnimationComputedInStep => {
  const element = elements.find((el) => el.id === animation.slideElementId);
  if (!element) {
    throw Error('Element not found');
  }

  const lastSameElementComputedAnimation = _.findLast(computedAnimations, {
    elementId: element.id,
  });

  const position =
    lastSameElementComputedAnimation?.position || element.position;
  const value =
    lastSameElementComputedAnimation?.element.value || element.value;

  const elementDefault: Pick<
    AnimationComputedInStep,
    'visibility' | 'position' | 'element' | 'codeStep'
  > = {
    visibility: true,
    position,
    // FIXME type segregation here isn't yet properly implemented, leading to a low-risk type error
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    element: {
      type: element.type,
      value,
    },
  };

  switch (animation.changeType) {
    case 'visibility': {
      elementDefault.visibility = isMissingAnimation
        ? false
        : animation.changeProps?.visibility;
      break;
    }
    case 'position':
      elementDefault.position = {
        ...animation.changeProps?.position,
      };
      break;
    case 'value':
      elementDefault.element.value = animation.changeProps;
      break;
    case 'codeStep':
      elementDefault.codeStep = animation.changeProps?.codeStep;
      break;
    case 'highlight':
      break;
    default:
      throw Error('Invalid change type');
  }

  return {
    ...elementDefault,
    id: animation.id,
    elementId: element.id,
    stepId: animation.stepId,
  } as AnimationComputedInStep;
};

const getMissingAnimations = (
  previousStepComputedAnimations: AnimationComputedInStep[],
  currentStepRawAnimations: Animation[],
) => {
  const missingAnimations = previousStepComputedAnimations.filter(
    (animatedStateChange) =>
      !currentStepRawAnimations.find(
        (anim) => anim.slideElementId === animatedStateChange.elementId,
      ),
  );

  return missingAnimations;
};

export const transformResponseReadLesson = (
  lessonResponse: ReadLessonResponse,
): Lesson => {
  const { slides, assignmentsPassed, allSlidesPassed, ...lesson } =
    lessonResponse;

  const slidesTransformed = slides.map((slide) => {
    let previousStepComputedAnimations: AnimationComputedInStep[] = [];
    const slideComputedAnimations: AnimationComputedInStep[] = [];
    const steps = slide.steps.map((step, i) => {
      const computed: AnimationComputedInStep[] = [];
      const previousStep = slide.steps[i - 1];

      if (step.animatedStateChanges.length === 0 && previousStep) {
        computed.push(...previousStepComputedAnimations);
      } else if (step.animatedStateChanges.length) {
        step.animatedStateChanges.forEach((animatedStateChange) => {
          const computedAnimation = getComputedAnimation(
            slide.elements,
            animatedStateChange,
            false,
            slideComputedAnimations,
          );

          computed.push(computedAnimation);
          slideComputedAnimations.push(computedAnimation);
        });

        if (previousStep) {
          const missingAnimations = getMissingAnimations(
            previousStepComputedAnimations,
            step.animatedStateChanges,
          );
          if (missingAnimations.length) {
            computed.push(...missingAnimations);
            slideComputedAnimations.push(...missingAnimations);
          }
        }
      }

      previousStepComputedAnimations = computed;

      const animations = {
        raw: step.animatedStateChanges,
        computed,
      };

      const transformedNarration = produce(step.narration, (draft) => {
        const COURSES_WITH_CONVERTED_NARRATION = [
          'BdBKnlZyxEvgNFNv10fo23pfrceETQ0A', // JavaScript course
          'eelyNMYJKXeNJAbjssSEQz0m88XvnhX6', // Python course
          'rhvTkx1L9AqOi7lc4CiBjTXLSqFyyZat', // Landing page course
        ];
        const CONVERTED_NARRATION_FILE_BITRATE = 256;
        const CONVERTED_NARRATION_FILE_EXTENSION = 'm4a';
        const EARLIEST_M4A_AVAILABLE_DATE = new Date('2024-12-07'); // 1.19.0 release date

        const shouldConvertNarration =
          COURSES_WITH_CONVERTED_NARRATION.includes(lesson.course.id) ||
          (step.narration &&
            parseISO(step.narration.createdAt) >= EARLIEST_M4A_AVAILABLE_DATE);

        if (!draft || !shouldConvertNarration) {
          return;
        }

        draft.asset.url = `${draft.asset.url}-${CONVERTED_NARRATION_FILE_BITRATE}.${CONVERTED_NARRATION_FILE_EXTENSION}`;
      });

      return {
        ...step,
        narration: transformedNarration,
        animations,
      };
    });

    return {
      ...slide,
      animations: {
        raw: steps.flatMap((step) => step.animations.raw),
        computed: slideComputedAnimations,
      },
      steps,
      stepIds: steps.map((step) => step.id),
    };
  });

  return {
    ...lesson,
    areAllSlidesSeen: allSlidesPassed,
    areAllAssignmentsPassed: assignmentsPassed,
    slides: slidesTransformed,
    slideIds: slidesTransformed.map((slide) => slide.id),
  };
};
