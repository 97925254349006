import { api } from '../../api';
import { API_TAGS } from '../../api-tags';
import { transformStorageResponseReadLesson } from './api-lesson.storage-transformer';
import { updateStorageReadLesson } from './api-lesson.storage-updater';
import { transformResponseReadLesson } from './api-lesson.transformer';
import {
  CreateLessonQueryArgs,
  CreateLessonResponse,
  DeleteLessonQueryArgs,
  DeleteLessonResponse,
  ReadLessonQueryArgs,
  ReadLessonResponse,
  ReorderLessonSlidesQueryArgs,
  ReorderLessonSlidesResponse,
  UpdateLessonCurrentSlideQueryArgs,
  UpdateLessonCurrentSlideResponse,
  UpdateLessonQueryArgs,
  UpdateLessonResponse,
} from './types';

export const {
  useCreateLessonMutation,
  useReadLessonQuery,
  useUpdateLessonMutation,
  useUpdateLessonCurrentSlideMutation,
  useDeleteLessonMutation,
  useReorderLessonSlidesMutation,
  util: { updateQueryData: apiLessonUpdateQueryData },
} = api.injectEndpoints({
  endpoints: (builder) => ({
    createLesson: builder.mutation<CreateLessonResponse, CreateLessonQueryArgs>(
      {
        query: ({ payload, params }) => ({
          url: '/lessons',
          method: 'post',
          payload: {
            ...payload,
            // FIXME: once path of the backend endpoint is changed to courses/{courseId}/lessons
            // remove courseId from the payload and include in the url
            courseId: params.courseId,
          },
        }),
        invalidatesTags: (_result, error, { params }) =>
          error ? [] : [API_TAGS.course(params.courseId)],
      },
    ),

    readLesson: builder.query<
      ReturnType<typeof transformResponseReadLesson>,
      ReadLessonQueryArgs
    >({
      query: ({ params, query }) => ({
        url: `/lessons/${params.lessonId}?courseId=${query.courseId}`,
        method: 'get',
      }),
      providesTags: (_result, _error, { params }) => [
        API_TAGS.lesson(params.lessonId),
      ],
      transformResponse: async (lessonResponse: ReadLessonResponse) =>
        transformStorageResponseReadLesson(
          await updateStorageReadLesson(
            transformResponseReadLesson(lessonResponse),
          ),
        ),
    }),

    updateLesson: builder.mutation<UpdateLessonResponse, UpdateLessonQueryArgs>(
      {
        query: ({ params, payload }) => ({
          url: `/lessons/${params.lessonId}`,
          method: 'patch',
          payload,
        }),
        invalidatesTags: (_result, error, { params }) =>
          error
            ? []
            : [
                API_TAGS.course(params.courseId),
                API_TAGS.lesson(params.lessonId),
              ],
      },
    ),

    updateLessonCurrentSlide: builder.mutation<
      UpdateLessonCurrentSlideResponse,
      UpdateLessonCurrentSlideQueryArgs
    >({
      query: ({ params, payload }) => ({
        url: `/lessons/${params.lessonId}/currentSlide`,
        path: '/lessons/:lessonId/currentSlide',
        params,
        method: 'put',
        payload,
      }),
      invalidatesTags: (_result, error, { params }) =>
        error ? [] : [API_TAGS.lesson(params.lessonId)],
    }),

    deleteLesson: builder.mutation<DeleteLessonResponse, DeleteLessonQueryArgs>(
      {
        query: ({ params }) => ({
          url: `/lessons/${params.lessonId}`,
          method: 'delete',
        }),
        invalidatesTags: (_result, error, { params }) =>
          error ? [] : [API_TAGS.course(params.courseId)],
      },
    ),

    reorderLessonSlides: builder.mutation<
      ReorderLessonSlidesResponse,
      ReorderLessonSlidesQueryArgs
    >({
      query: ({ params, payload }) => ({
        url: `/lessons/${params.lessonId}/reorder-slides`,
        method: 'put',
        payload,
      }),
      invalidatesTags: (_result, error, { params }) =>
        error ? [] : [API_TAGS.lesson(params.lessonId)],
    }),
  }),
});
