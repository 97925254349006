import Bugsnag from '@bugsnag/browser';
import { apiLessonUpdateQueryData } from '../lesson/api-lesson.endpoint';
import { invariant } from '../../../utils/invariant';
import {
  DeleteAnimationMutationContext,
  DeleteAnimationQueryArgs,
} from './api-animation.type';

export const optimisticallyDeleteAnimation = async (
  { params }: DeleteAnimationQueryArgs,
  { dispatch, queryFulfilled }: DeleteAnimationMutationContext,
): Promise<void> => {
  const patchResult = dispatch(
    apiLessonUpdateQueryData(
      'readLesson',
      {
        params: {
          lessonId: params.lessonId,
        },
        query: {
          courseId: params.courseId,
        },
      },
      // Immer is used to safely update the state in a mutable way without mutating the original state
      /* eslint-disable no-param-reassign */
      (draft) => {
        invariant(draft, 'Draft is undefined');

        const animationIdToDelete = params.id;
        const slideInLesson = draft.slides.find((slide) =>
          slide.steps.find((step) =>
            step.animations.computed.find(
              (animation) => animation.id === animationIdToDelete,
            ),
          ),
        );

        invariant(slideInLesson, 'Slide in lesson is undefined');

        slideInLesson.steps.forEach((step) => {
          step.animations.raw = step.animations.raw.filter(
            (animation) => animation.id !== params.id,
          );
          step.animations.computed = step.animations.computed.filter(
            (animation) => animation.id !== params.id,
          );
        });
      },
      /* eslint-enable no-param-reassign */
    ),
  );

  try {
    await queryFulfilled;
  } catch (error: unknown) {
    const errorMessage = 'Failed to delete animation';

    if (error instanceof Error) {
      Bugsnag.notify(error);
    } else {
      Bugsnag.notify(new Error(errorMessage));
    }

    patchResult.undo();
  }
};
